import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {createOrgPath} from '@core/lib/organizations';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import CampaignNoiseForm from '@analytics/components/forms/CampaignNoiseForm';
import {CampaignDocument} from '@analytics/graphql-api';
import UpdateCampaignNoiseMutation from '@analytics/graphql-api/_old_mutations/UpdateCampaignNoise';

class CampaignNoiseUpdate extends React.Component {
  state = {
    loading: false,
    complete: false,
    errors: [],
  };

  onSubmit = ({
    startAt,
    endAt,
    name,
    targetImpressions,
    usePublisherImpressions,
  }) => {
    const {updateCampaignNoise, campaignNoise} = this.props;

    if (!startAt || !endAt) {
      return;
    }

    this.setState({
      loading: true,
    });

    const {id} = campaignNoise;

    updateCampaignNoise({
      campaignNoiseId: id,
      startAt: startAt.format('YYYY-MM-DD'),
      endAt: endAt.format('YYYY-MM-DD'),
      name,
      targetImpressions: parseInt(targetImpressions),
      usePublisherImpressions,
    })
      .then(() => {
        this.setState({
          complete: true,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  render() {
    const {
      organization,
      campaign,
      campaignNoise,
      liftReport,
      match: {url},
    } = this.props;
    const {loading, complete, errors} = this.state;

    if (complete) {
      return (
        <Redirect
          to={createOrgPath(organization, `/campaigns/${campaign.slug}/lift`)}
        />
      );
    }

    return (
      <ResponsiveContent containerSize='large'>
        <CardHeader
          rightContent={
            <Button color='error' to={`${url}/delete`}>
              Delete
            </Button>
          }>
          Update Household Control Group
        </CardHeader>
        <Card>
          <CampaignNoiseForm
            loading={loading}
            onSubmit={this.onSubmit}
            errors={errors}
            organization={organization}
            campaign={campaign}
            campaignNoise={campaignNoise}
            onErrorClose={() => {
              this.setState({
                errors: [],
              });
            }}
          />
        </Card>
      </ResponsiveContent>
    );
  }
}

export default graphql(UpdateCampaignNoiseMutation, {
  props: ({ownProps: {organization}, mutate}) => ({
    updateCampaignNoise: (input) => {
      return mutate({
        variables: {
          input,
        },
        update: (
          store,
          {
            data: {
              updateCampaignNoise: {campaignNoise},
            },
          }
        ) => {
          const query = {
            query: CampaignDocument,
            variables: {
              organizationId: organization.id,
              slug: campaign.slug,
            },
          };

          query.data = store.readQuery(query);

          query.data.me.organization.campaign.campaignNoises =
            query.data.me.organization.campaign.campaignNoises
              .filter(({id}) => id != campaignNoise.id)
              .concat(campaignNoise);

          store.writeQuery(query);
        },
      });
    },
  }),
})(CampaignNoiseUpdate);
