import {gql} from '@apollo/client';
import CampaignNoiseFragment from '@analytics/graphql-api/_old_queries/fragments/CampaignNoiseFragment';

export default gql`
  mutation updateCampaignNoise($input: UpdateCampaignNoiseInput!) {
    authed {
      success
    }
    updateCampaignNoise(input: $input) {
      campaignNoise {
        ...CampaignNoise
      }
    }
  }
  ${CampaignNoiseFragment}
`;
