import {useState} from 'react';
import {useQuery} from '@apollo/client';
import {format} from 'd3-format';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {isBuying} from '@core/lib/organizations';
import {DateRangeBar} from '@core/ui/DatePicker';
import EmptyMessage from '@core/ui/EmptyMessage';
import {Stack} from '@core/ui/Layout';
import {LoadingSpinner} from '@core/ui/Loading';
import Select from '@core/ui/Select';
import {InfoTooltip} from '@core/ui/Tooltip';
import {CampaignOmniChartTooltip} from './CampaignOmniChartTooltip';
import {getCampaignOmniChartData} from './getCampaignOmniChartData';
import {INTERVALS, getXAxisTicks} from './helpers';

const CampaignOmniChartBaseImpl = ({
  attribution,
  campaignPodcasts,
  downloads,
  height,
  interval,
  organization,
}) => {
  const {data, episodes} = getCampaignOmniChartData({
    attribution,
    campaignPodcasts,
    downloads,
    interval,
  });
  const startDate = dayjs(data[0].x);
  const endDate = dayjs(data[data.length - 1].x);
  const xTicks = getXAxisTicks(startDate, endDate);
  let totalVisitors = 0;
  let totalImpressions = 0;

  data.forEach((d) => {
    totalVisitors += d.visitors;
    totalImpressions += d.impressions;
  });

  return (
    <div css={{color: 'var(--text-muted)', fontSize: '0.75rem'}}>
      <ResponsiveContainer width='100%' height={height}>
        <ComposedChart
          data={data}
          barGap={0}
          barCategoryGap={0}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}>
          <defs>
            <linearGradient
              id='impressionsGradient'
              x1='0'
              y1='0'
              x2='0'
              y2='1'>
              <stop offset='5%' stopColor='var(--green)' stopOpacity={0.25} />
              <stop offset='95%' stopColor='var(--green)' stopOpacity={0} />
            </linearGradient>
            <linearGradient id='visitorsGradient' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='var(--blue)' stopOpacity={0.3} />
              <stop offset='95%' stopColor='var(--blue)' stopOpacity={0} />
            </linearGradient>
          </defs>
          <Tooltip
            content={({payload}: TooltipProps<number, string>) => (
              <CampaignOmniChartTooltip
                payload={payload}
                interval={interval}
                episodes={episodes}
              />
            )}
            cursor={{
              stroke: 'var(--gray-4)',
              strokeDasharray: '4',
            }}
          />
          <CartesianGrid
            stroke='var(--border-default)'
            strokeDasharray='0 0'
            vertical={false}
          />
          <XAxis
            xAxisId='x'
            type='number'
            scale='time'
            dataKey='x'
            domain={['dataMin', 'dataMax']}
            tick={{fill: 'var(--text-muted)'}}
            ticks={xTicks}
            tickSize={10}
            tickLine={false}
            tickFormatter={(timeStr) =>
              dayjs(timeStr).utc().format('D MMM').toUpperCase()
            }
            axisLine={false}
          />
          <YAxis yAxisId='episodes' dataKey='episode' domain={[0, 1]} hide />
          <YAxis
            yAxisId='impressions'
            tickFormatter={(val) => format('.4s')(val)}
            tick={{fill: 'var(--text-muted)'}}
            tickSize={10}
            axisLine={false}
            tickLine={false}
          />

          {episodes.length
            ? [
                episodes.map(({x}) => (
                  <ReferenceLine
                    key={x}
                    xAxisId='x'
                    x={x}
                    yAxisId='episodes'
                    stroke='rgb(255 148 213 / 40%)'
                    strokeWidth={3}
                  />
                )),
                <Bar
                  xAxisId='x'
                  dataKey='episodes'
                  yAxisId='episodes'
                  barSize={0}
                  fill='transparent'
                  isAnimationActive={false}
                />,
              ]
            : null}
          <Area
            xAxisId='x'
            yAxisId='impressions'
            strokeWidth={2}
            dataKey='impressions'
            stroke='var(--green)'
            fill='url(#impressionsGradient)'
            dot={false}
          />
          {isBuying(organization)
            ? [
                <Area
                  xAxisId='x'
                  yAxisId='visitors'
                  strokeWidth={2}
                  dataKey='visitors'
                  stroke='var(--blue)'
                  fill='url(#visitorsGradient)'
                  dot={false}
                />,
                <YAxis
                  yAxisId='visitors'
                  orientation='right'
                  domain={[0, (dataMax) => Math.max(1, dataMax) * 4]}
                  tickFormatter={(val) => format('.3s')(val)}
                  tick={{fill: 'var(--text-muted)'}}
                  tickSize={10}
                  axisLine={false}
                  tickLine={false}
                />,
              ]
            : null}
        </ComposedChart>
      </ResponsiveContainer>
      <div
        css={{
          textAlign: 'center',
          marginTop: '0.75rem',
          fontSize: '0.8125rem',
        }}>
        From {startDate.format('MMM DD')} to {endDate.format('MMM DD')} there
        were {comma(totalImpressions)} impressions
        {isBuying(organization) ? (
          <>
            {' '}
            and {comma(totalVisitors, 2)} visitors.{' '}
            <InfoTooltip description='Visitors on this chart are always unmodelled, so the sum of the hourly visitors could be different than the visitors on the global campaign stats.' />
          </>
        ) : (
          '.'
        )}
      </div>
    </div>
  );
};

const DownloadsUnavailable = () => {
  return (
    <EmptyMessage title='No impressions found'>
      Ad Analytics does not have any impressions for the selected date range.
    </EmptyMessage>
  );
};

const CampaignOmniChartBase = ({
  campaign,
  dataAccessorFn,
  height = 300,
  organization,
  query,
  headerTitle = 'Campaign Performance',
  queryVariables,
  withoutDatePicker = false,
}) => {
  const [interval, setInterval] = useState(INTERVALS[0].value);
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(7, 'd'),
    dayjs(),
  ]);

  const campaignStartDate = dayjs(campaign.startAt ?? campaign.expectedStartAt);
  const {loading, error, data} = useQuery(query, {
    variables: {
      organizationId: organization.id,
      ...(withoutDatePicker
        ? {}
        : {
            after: dateRange[0].utc().startOf('d').format('YYYY-MM-DD HH:00'),
            before: dateRange[1].utc().startOf('d').format('YYYY-MM-DD HH:00'),
          }),
      ...queryVariables,
    },
  });

  return (
    <>
      <Stack
        alignItems='center'
        gap={2}
        css={`
          margin-bottom: 1.75rem;
        `}>
        <h3
          css={`
            font-size: var(--font-size-h3);
            font-weight: var(--font-weight-bold);
            margin-right: auto;
          `}>
          {headerTitle}
        </h3>
        {!withoutDatePicker ? (
          <DateRangeBar
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            onDatesChange={({startDate, endDate}) => {
              setDateRange([startDate, endDate]);
            }}
            minDate={campaignStartDate}
          />
        ) : null}
        <Select
          items={INTERVALS}
          defaultValue={interval}
          onSelect={({value}) => setInterval(value)}
          rules={() => ({color: 'var(--text-muted)'})}
        />
      </Stack>
      {loading ? (
        <LoadingSpinner centered />
      ) : error ? (
        <DownloadsUnavailable />
      ) : (
        (() => {
          const {attribution, downloads, campaignPodcasts} =
            dataAccessorFn(data);

          if (downloads.hourly.length === 0) {
            return <DownloadsUnavailable />;
          }
          return (
            <CampaignOmniChartBaseImpl
              attribution={attribution}
              campaignPodcasts={campaignPodcasts}
              downloads={downloads}
              height={height}
              interval={interval}
              organization={organization}
            />
          );
        })()
      )}
    </>
  );
};

export default CampaignOmniChartBase;
