import React, {useReducer, useState} from 'react';
import {QueryResult} from '@apollo/client';
import {Query} from '@apollo/client/react/components';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ChartLegend from '@core/components/ChartLegend';
import {ChartTimeTooltip} from '@core/components/ChartTimeTooltip';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {Element} from '@core/style';
import {BLUE, GREEN, PINK, PURPLE, RED_ORANGE} from '@core/style';
import {DateRangeInput} from '@core/ui/DatePicker';
import EmptyMessage from '@core/ui/EmptyMessage';
import {
  FieldGroup,
  FieldSet,
  Form,
  Help,
  Input,
  Legend,
  ToggleContainer,
} from '@core/ui/Form';
import {Label, Submit} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import Toggle from '@core/ui/Toggle';
import {LiftReportPreCreateCampaignDocument} from '@analytics/graphql-api';

const getDailyDownloads = (hourly) => {
  const daily = {};

  hourly.forEach(({hour, count}) => {
    const day = dayjs(hour).format('MMMM DD, YYYY');
    daily[day] = daily[day] || [];
    daily[day].push(count);
  });

  let max = 0;
  const downloads = Object.keys(daily)
    .map((day) => {
      const y = daily[day].reduce((a, b) => a + b, 0);
      max = y > max ? y : max;

      return {
        x: dayjs(day).toDate().getTime(),
        downloads: y,
      };
    })
    .map(({x, downloads}) => {
      return {x, downloads: Math.round((downloads * 100) / max)};
    });

  return downloads;
};

const getChartData = ({historical, hourly}) => {
  const downloads = getDailyDownloads(hourly);
  const data = historical.map((obj) => {
    obj.x = dayjs(dayjs(obj.day).format('MMMM DD, YYYY')).toDate().getTime();
    return obj;
  });

  downloads.forEach(({x, downloads}) => {
    const match = data.find(({x: _x}) => x === _x);
    if (match) {
      match.downloads = downloads;
    } else {
      data.push({x, downloads});
    }
  });

  return data;
};

const SERIES = [
  {name: 'downloads', color: BLUE},
  {name: 'init', color: GREEN},
  {name: 'installed', color: PURPLE},
  {name: 'lead', color: RED_ORANGE},
  {name: 'purchase', color: PINK},
];

const LiftReportRequestChart = ({data}) => {
  const [selectedSeries, setSelectedSeries] = useState(SERIES);
  const activeSeriesNames = selectedSeries.map(({name}) => name);
  if (activeSeriesNames.length !== SERIES.length) {
    data = data
      .map((d) => {
        if (Object.keys(d).some((r) => activeSeriesNames.includes(r))) {
          return d;
        }
        return false;
      })
      .filter(Boolean);
  }

  return (
    <Element>
      <ResponsiveContainer width='100%' height={300}>
        <BarChart data={data}>
          <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
          <XAxis
            dataKey='x'
            domain={['auto, auto']}
            type='number'
            scale='time'
            padding={{left: 20, right: 20}}
            tickFormatter={(timeStr) => dayjs(timeStr).utc().format('MMM DD')}
          />
          <YAxis tickFormatter={(value) => comma(value)} />
          <Tooltip
            content={({...props}) => (
              <ChartTimeTooltip dateFormat='dddd, MMM DD' {...props} />
            )}
            wrapperStyle={{textTransform: 'capitalize'}}
          />
          {selectedSeries.map(({name, color}) => (
            <Bar dataKey={name} fill={color} />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <Element rules={() => ({margin: '0.5rem 0'})}>
        <ChartLegend
          series={SERIES}
          setSelectedSeries={setSelectedSeries}
          selectedSeries={selectedSeries}
          horizontal
          multipleSelect
        />
      </Element>
    </Element>
  );
};

const getInitialState = ({campaign, campaignNoise}) => {
  let baseState = {
    name: 'Control Households',
    targetImpressions: campaign.goal,
    usePublisherImpressions: false,
  };

  if (campaignNoise) {
    const {startAt, endAt, name, targetImpressions, usePublisherImpressions} =
      campaignNoise;

    baseState = Object.assign(baseState, {
      startAt: dayjs(startAt),
      endAt: dayjs(endAt),
      name: name ? name : '',
      targetImpressions: targetImpressions ? targetImpressions : '',
      usePublisherImpressions,
    });
  } else {
    baseState = Object.assign(baseState, {
      startAt: dayjs(campaign.startAt).subtract(1, 'month'),
      endAt: dayjs(campaign.endAt).isAfter(dayjs())
        ? dayjs()
        : dayjs(campaign.endAt),
    });
  }
  return baseState;
};

const CampaignHouseholdsForm = ({
  organization,
  campaign,
  campaignNoise,
  loading,
  errors,
  onErrorClose,
  onSubmit,
}) => {
  const [state, setState] = useReducer((state, newState) => {
    return {...state, ...newState};
  }, getInitialState({campaign, campaignNoise}));

  const _onDateChange = ({startDate, endDate}) => {
    setState({
      startAt: startDate,
      endAt: endDate,
    });
  };

  const _onChange = ({target: {name, value}}) => {
    const update = {};
    update[name] = value;
    setState(update);
  };

  const _onToggleChange = (value, name) => {
    const update = {};
    update[name] = !state[name];
    setState(update);
  };

  const _onSubmit = () => {
    const {startAt, endAt, name, targetImpressions, usePublisherImpressions} =
      state;

    onSubmit({
      startAt,
      endAt,
      name,
      usePublisherImpressions,
      targetImpressions,
    });
  };

  const hardEnd = dayjs(campaign.endAt).isAfter(dayjs())
    ? dayjs()
    : dayjs(campaign.endAt);

  const hardStart = dayjs(campaign.startAt).subtract(1, 'month');

  return (
    <Query
      query={LiftReportPreCreateCampaignDocument}
      fetchPolicy='no-cache'
      variables={{
        organizationId: organization.id,
        campaignId: campaign.id,
        after: state.startAt.format('YYYY-MM-DD'),
        before: state.endAt.format('YYYY-MM-DD'),
      }}>
      {({loading, error, data}: QueryResult) => {
        if (loading) {
          return <Loading centered />;
        }
        if (error) {
          return <EmptyMessage>No data found.</EmptyMessage>;
        }

        const {
          downloads: {hourly},
          stateOverview: {
            pixel: {historical},
          },
        } = data.me.organization.campaign;

        return (
          <Form
            onSubmit={_onSubmit}
            loading={loading}
            errors={errors}
            onClose={onErrorClose}>
            <DateRangeInput
              onDatesChange={_onDateChange}
              startDate={state.startAt}
              endDate={state.endAt}
              isOutsideRange={(date) => {
                return !(
                  date.isSameOrAfter(hardStart) && date.isSameOrBefore(hardEnd)
                );
              }}
            />
            <Element rules={() => ({margin: '2rem 0', fontSize: '0.8125rem'})}>
              <LiftReportRequestChart
                data={getChartData({historical, hourly})}
              />
            </Element>
            <FieldSet rules={() => ({marginTop: 0})}>
              <Legend>Control Household Range</Legend>
              <FieldGroup>
                <Label>Group Name</Label>
                <Input
                  type='text'
                  name='name'
                  value={state.name}
                  onChange={_onChange}
                  placeholder='Noise 1/2029 - 5/2029'
                  disabled={loading}
                />
              </FieldGroup>

              <FieldGroup>
                <Label>Target Impressions</Label>
                <Input
                  type='text'
                  name='targetImpressions'
                  value={state.targetImpressions}
                  onChange={_onChange}
                  placeholder={`${campaign.goal}`}
                  disabled={loading}
                />
                <Help>
                  Target number of Households to use in the Control Group.
                </Help>
              </FieldGroup>

              <FieldGroup>
                <ToggleContainer
                  title='Use Publisher Impressions'
                  helpText={
                    <>
                      {' '}
                      By Default Ad Analytics uses it's household graph to
                      create a control group. If you wish to only use
                      impressions from <b>{organization.name}</b> then please
                      select this box.{' '}
                      <b>
                        Note that you must of Ad Analytics' RSS prefix install.
                      </b>
                    </>
                  }>
                  <Toggle
                    defaultChecked={state.usePublisherImpressions}
                    onChecked={(value) =>
                      _onToggleChange(value, 'usePublisherImpressions')
                    }
                    disabled={loading}
                    style={{padding: '0.125rem'}}
                  />
                </ToggleContainer>
              </FieldGroup>
            </FieldSet>

            <Element rules={() => ({padding: '1.25rem 0', textAlign: 'right'})}>
              <Submit disabled={loading}>
                {campaignNoise ? 'Update' : 'Add Noise Range'}
              </Submit>
            </Element>
          </Form>
        );
      }}
    </Query>
  );
};

export default CampaignHouseholdsForm;
