import {useMemo} from 'react';
import styled from 'styled-components';
import CampaignStateBadge from '@core/components/CampaignStateBadge/CampaignStateBadge';
import dayjs from '@core/lib/dayjs';
import {capitalize, comma, financial} from '@core/lib/filters';
import {Text} from '@core/ui/Text';
import Tags from '@analytics/components/tags/Tags';
import {canUpdateCampaign} from '@analytics/lib/campaigns';
import {CampaignDraftProps} from '..';
import {CampaignPathButton, Row, Title} from '../_shared';

const fmtDate = (date: string) => dayjs(date).format('MMMM DD, YYYY');

export function CampaignStaticDetails({
  organization,
  campaign,
}: CampaignDraftProps) {
  const details = useMemo(
    () => [
      {
        title: 'Expected Start',
        value: campaign.expectedStartAt
          ? fmtDate(campaign.expectedStartAt)
          : '—',
      },
      {
        title: 'Expected End',
        value: campaign.expectedEndAt ? fmtDate(campaign.expectedEndAt) : '—',
      },
      ...(campaign.goLiveDate
        ? [
            {
              title: 'Dashboard Live Date',
              value: fmtDate(campaign.goLiveDate),
            },
          ]
        : []),
      {
        title: 'Type',
        value: capitalize(campaign.kind ?? ''),
      },
      {
        title: 'State',
        value: <CampaignStateBadge state={campaign.state} />,
      },
      {
        title: 'Publisher',
        value: campaign.organization?.name ?? '-',
      },
      {
        title: 'Advertiser',
        value: campaign.advertiser?.organization?.name ?? '—',
      },
      {
        title: 'Impressions Goal',
        value: campaign.goal ? comma(campaign.goal) : '-',
      },
      {
        title: 'Cost',
        value: campaign.cost ? financial(campaign.cost) : '-',
      },
      ...(organization.isManager
        ? []
        : [
            {
              title: 'Tags',
              value: (
                <div css={{display: 'inline-flex'}}>
                  <Tags
                    instance={campaign}
                    organization={campaign.organization}
                    contentType='campaigns.campaign'
                    showEditButtonIfEmpty
                    overlayProps={{
                      horizontalAlign: 'left',
                      verticalAlign: 'middle',
                      noHorizontalOverlap: true,
                      noVerticalOverlap: true,
                    }}
                    wrap
                  />
                </div>
              ),
            },
          ]),
    ],
    [organization, campaign]
  );

  return (
    <Row noBorder>
      <Title
        rightContent={
          canUpdateCampaign({organization, campaign}) && (
            <CampaignPathButton toCampaignPath='update'>
              Edit Campaign
            </CampaignPathButton>
          )
        }>
        Details
      </Title>
      {details.map(({title, value}) => (
        <DetailGrid key={title}>
          <Text color='muted' variant='body2'>
            {title}
          </Text>
          <Text variant='body2'>{value}</Text>
        </DetailGrid>
      ))}
    </Row>
  );
}

const DetailGrid = styled.div`
  display: grid;
  gap: var(--spacing-4);
  grid-template-columns: 8rem 1fr;
  padding: var(--spacing-1) 0;
`;
