import {Control, Controller} from 'react-hook-form';
import {DateRangeInput} from '@core/ui/DatePicker';
import {FormHelp, Label} from '@core/ui/FormElements';
import {CampaignFormData} from '../_types';
import {FormGroup} from './_shared';

export function CampaignDatesField({
  control,
}: {
  control: Control<CampaignFormData>;
}) {
  return (
    <FormGroup>
      <Label htmlFor='expectedDates'>Expected Start / End</Label>
      <Controller
        name='expectedDates'
        control={control}
        rules={{
          validate: ({expectedStartAt, expectedEndAt}) =>
            (!!expectedStartAt && !!expectedEndAt) ||
            'Please provide expected dates.',
        }}
        render={({field, fieldState}) => (
          <DateRangeInput
            startDate={field.value?.expectedStartAt ?? undefined}
            endDate={field.value?.expectedEndAt ?? undefined}
            invalid={!!fieldState.error}
            onDatesChange={({startDate, endDate}) =>
              field.onChange({
                expectedStartAt: startDate,
                expectedEndAt: endDate,
              })
            }
          />
        )}
      />
      <FormHelp>Impressions will run within the selected date range.</FormHelp>
    </FormGroup>
  );
}
